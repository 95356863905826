
header {
  max-width: 1000px;
  margin: auto;
}



main label {
  color: black;
  font-weight: bold;
}
footer {
  max-width: 1000px;
  margin: auto;
}

legend {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}