.calculate {
  max-width: 380px;
}

.pulse {
  box-shadow: 0 0 0 0 rgb(236,24,104);
  cursor: pointer;
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66,0,0,1);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.66,0,0,1);
  -ms-animation: pulse 1.25s infinite cubic-bezier(0.66,0,0,1);
  animation: pulse 1.25s infinite cubic-bezier(0.66,0,0,1);
}

.pulse:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  animation: none;
}

.um-cta {
    background: #E5007D;
    border: medium none;
    border-radius: 5px;
    color: #ffffff !important;
    font-size: 20px;
    padding: 15px 20px !important;
    width: 100% !important;
    border-radius: 99px;
    
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 10px rgba(232,76,61,0);
  }
}

@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 10px rgba(232,76,61,0);
  }
}

@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 10px rgba(232,76,61,0);
  }
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 10px rgba(232,76,61,0);
  }
}

.button-1 {
  margin: 5px 0 5px 0;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  transition: all 0.25s ease;
  overflow: hidden;
  position: relative;

  background-color: #e74c3c;
  color: white;
  box-shadow: 0px 4px rgba(0, 0, 0, 0.2);
  margin: 0 7px 11px 0;
  white-space: pre-wrap;
  transition: 0.6s;
  position: relative;
  cursor: pointer;
  overflow: hidden;


  background-color: #e74c3c;
  color: white;
  border-color:white;
  box-shadow: 0px 4px rgba(0, 0, 0, 0.2);
  margin: 0 7px 11px 0;
  white-space: pre-wrap;
  transition: 0.6s;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  padding: 2px;
}



.button-1:hover {
  filter: brightness(100%) saturate(120%);
}



.hover-grow{
  filter: brightness(110%);
  transform: translate(-3px, -3px) scale(1.02);
}


.App {
  text-align: center;
  background-color: white;
  background-size: 100%, 100%; 
  z-index: 40;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.bg {
  background-color: #f2efef;
  width: 400px;
  height: 30px;
  margin: 0 auto;
}

.left {
  float: left;
}

.right {
  float: right;
}


.c-rainbow {
  counter-reset: rainbow;
  position: relative;
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
}
.c-rainbow__layer {
  --text-color: var(--color-foreground);
  counter-increment: rainbow;
  font-size: 3rem;
  color: var(--text-color);
  text-shadow: -1px -1px 0 var(--color-black), 1px -1px 0 var(--color-black), -1px 1px 0 var(--color-black), 1px 1px 0 var(--color-black), 4px 4px 0 rgba(0, 0, 0, 0.2);
  animation: rainbow 1.5s ease-in-out infinite;
}
.c-rainbow__layer:nth-child(1) {
  animation-delay: calc( 1 / var(--delay) * 1s);
  left: calc(var(--axis-x) * 1);
  z-index: -10;
}
.c-rainbow__layer:nth-child(2) {
  animation-delay: calc( 2 / var(--delay) * 1s);
  left: calc(var(--axis-x) * 2);
  z-index: -20;
}
.c-rainbow__layer:nth-child(3) {
  animation-delay: calc( 3 / var(--delay) * 1s);
  left: calc(var(--axis-x) * 3);
  z-index: -30;
}
.c-rainbow__layer:nth-child(4) {
  animation-delay: calc( 4 / var(--delay) * 1s);
  left: calc(var(--axis-x) * 4);
  z-index: -40;
}
.c-rainbow__layer:nth-child(5) {
  animation-delay: calc( 5 / var(--delay) * 1s);
  left: calc(var(--axis-x) * 5);
  z-index: -50;
}
.c-rainbow__layer:nth-child(6) {
  animation-delay: calc( 6 / var(--delay) * 1s);
  left: calc(var(--axis-x) * 6);
  z-index: -60;
}
.c-rainbow__layer:nth-child(7) {
  animation-delay: calc( 7 / var(--delay) * 1s);
  left: calc(var(--axis-x) * 7);
  z-index: -70;
}
.c-rainbow__layer:not(:first-child) {
  position: absolute;
  top: 0;
}
.c-rainbow__layer--white {
  --text-color: var(--color-white);
}
.c-rainbow__layer--orange {
  --text-color: var(--color-orange);
}
.c-rainbow__layer--red {
  --text-color: var(--color-red);
}
.c-rainbow__layer--violet {
  --text-color: var(--color-violet);
}
.c-rainbow__layer--blue {
  --text-color: var(--color-blue);
}
.c-rainbow__layer--green {
  --text-color: var(--color-green);
}
.c-rainbow__layer--yellow {
  --text-color: var(--color-yellow);
}

@keyframes rainbow {
  0%, 100% {
    transform: translatey(var(--axis-y));
  }
  50% {
    transform: translatey(calc(var(--axis-y) * -1));
  }
}



@-webkit-keyframes bar-animation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: red;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.button1{
  z-index:5000;
  height:35px;
  outline:none;
  float:right;
  text-align: center;
  margin-right:5px; 
  margin-top:.5%;
  display: block;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: .6em 1.4em .5em .8em;
  width: 15%;
  max-width: 20%;
  box-sizing: border-box;
  border: none;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: .5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --main-bg-color: #65dfc9;
  --secondary-bg-color: #6cdbeb;
}

.content {
  font-family: "Poppins", sans-serif;
  min-height: 100vh;
  background: var(--main-bg-color);
  background: linear-gradient(
    to right top,
    var(--main-bg-color),
    var(--secondary-bg-color)
  );
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  background-color: rgba(
    255,
    255,
    255,
    0.9
  ); /* slightly transparent fallback for Firefox */
  min-height: 80vh;
  width: 100%;
  border-radius: 2rem;
  z-index: 2;
  display: flex;
}

/* if backdrop support: very transparent and blurred */
@supports (
  (backdrop-filter: blur(2rem)) or (-webkit-backdrop-filter: blur(2rem))
) {
  .container {
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.3)
    );
    -webkit-backdrop-filter: blur(2rem);
    backdrop-filter: blur(2rem);
  }
}

.dashboard {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.3)
  );
  border-radius: 2rem;
}
.link {
  display: flex;
  padding: 1rem;
  margin: 1rem 0rem;
  align-items: center;
}
.link img {
  transform: scale(0.5);
}
.user,
.link h2,
.pro,
.progress {
  display: none;
}

.games {
  flex: 3;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.status {
  margin-bottom: 1rem;
}

.status input {
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.3)
  );
  border: none;
  width: 50%;
  padding: 0.5rem;
  border-radius: 2rem;
}


.threedbox{
  display: none;
  background: -moz-linear-gradient(
    top,
    #ffcb69 0%,
    #f0a733 50%,
    #f2a324 92%,
    #ffb730);
  background: -webkit-gradient(
    linear, left top, left bottom,
    from(#ffcb69),
    color-stop(0.50, #f0a733),
    color-stop(0.92, #f2a324),
    to(#ffb730));
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  border: 1px solid #965F06;
  -moz-box-shadow:
    0px 1px 1px rgba(000,000,000,0.5),
    inset 1px 2px 0px rgba(255,255,255,0.4);
  -webkit-box-shadow:
    0px 1px 1px rgba(000,000,000,0.5),
    inset 1px 2px 0px rgba(255,255,255,0.4);
  box-shadow:
    0px 1px 1px rgba(000,000,000,0.5),
    inset 1px 2px 0px rgba(255,255,255,0.4);
  text-shadow:
    1px 1px 2px rgba(000,000,000,0.7),
    0px 1px 0px rgba(255,255,255,0.4);
}



.card {
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    to left top,
    rgba(225, 246, 246, 0.8),
    rgba(225, 246, 246, 0.5)
  );
  border-radius: 1rem;
  margin: 2rem 0rem;
  padding: 2rem;
  box-shadow: 6px 6px 20px rgba(122, 122, 122, 0.212);
  justify-content: space-between;
}
.card img {
  width: 105px;
  height: 105px;
  border-radius: 1rem;
}
.card-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.percentage {
  font-weight: bold;
  background: linear-gradient(
    to right top,
    var(--main-bg-color),
    var(--secondary-bg-color)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h1 {
  color: #426696;
  font-weight: 600;
  font-size: 2rem;
  opacity: 0.8;
}
h2,
p {
  color: #658ec6;
  font-weight: 500;
}

h3 {
  color: #426696;
  font-weight: 600;
  opacity: 0.8;
}

@media screen and (min-width: 640px) {
  .link img {
    transform: scale(1);
  }
  .link {
    margin: 2rem 0rem;
    padding: 1rem 5rem;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    width: 80%;
  }
  .circle1,
  .circle2 {
    background: white;
    background: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.3)
    );
    height: 20rem;
    width: 20rem;
    position: absolute;
    border-radius: 50%;
  }
  .circle1 {
    top: 5%;
    right: 15%;
  }
  .circle2 {
    bottom: 5%;
    left: 25%;
  }
  .dashboard {
    justify-content: space-evenly;
  }
  .user {
    display: block;
  }
  .user img {
    border-radius: 50%;
  }

  .link h2 {
    display: block;
    padding: 0 2rem;
  }
  .pro {
    display: block;
    background: linear-gradient(
      to right top,
      var(--main-bg-color),
      var(--secondary-bg-color)
    );
    border-radius: 2rem;
    color: white;
    padding: 1rem;
    position: relative;
  }
  .pro img {
    position: absolute;
    top: -10%;
    right: 10%;
  }
  .pro h2 {
    width: 40%;
    color: white;
    font-weight: 600;
  }
  .games {
    margin: 5rem;
  }
  h1 {
    font-size: 3rem;
  }
  .status {
    margin-bottom: 3rem;
  }
  .card {
    flex-direction: row;
  }
  .card img {
    margin-right: 0.5rem;
  }
  .progress {
    display: block;
    background: linear-gradient(
      to right top,
      var(--main-bg-color),
      var(--secondary-bg-color)
    );
    width: 100%;
    height: 25%;
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
  }
  .progress::after {
    content: "";
    width: 100%;
    height: 100%;
    background: rgb(236, 236, 236);
    position: absolute;
    left: 60%;
  }
}


.dropdown1 {
  z-index:5000;
  height:35px;
  outline:none;
  text-align: center;
  margin-right:5px; 
  margin-top:.5%; 
  display: block;
	font-size: 12px;
	font-family: sans-serif;
	font-weight: 700;
	color: #444;
	line-height: 1.3;
	padding: .6em 1.4em .5em .8em;
	max-width: 80%;
  width:80%;
	box-sizing: border-box;
	border: none;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
	border-radius: .5em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color:'#1F2A37';
  color:'white' 
}

option {
  margin-top: 5px;
  font-family: sans-serif;
  font-weight:500;
  border-radius: 5px;
  border:none;
  
}
option:hover {
  background-color:  lightgray;
}

.dropdown1:hover {
	/* border-color: #888; */
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #000;
  line-height:50px;
  text-align:center;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.topnav {
  /* background-color: #333; */
  overflow: hidden;
  z-index: -5;
}

input[type="text"],
input[type="password"]
        {
        display: block;
        box-sizing: border-box;
        margin-bottom: 20px;
        padding: 4px;
        width: 250px;
        height: 32px;
        border: none;
        outline: none;
        border-bottom: 1px solid #aaa;
        font-family: Helvetica;
        font-weight: 400;
        font-size: 15px;
        transition: 0.2s ease;
        }

        #user_input {
          margin-top:30px;
          border: none;
          border-radius: 4px;
          background-color: #ededed;
          padding: 10px;
          width: 50%;
          margin-left:25%;
          height: 45px;    
          }
 

          #pass_input {
            border: none;
            border-radius: 4px;
            background-color: #ededed;
            padding: 10px;
            width: 50%;
            margin-left:25%;
            height: 45px;    
            }

            .login-button
            {
            vertical-align: middle;
            width: 50%;
            height: 45px; 
            background:#9AD8F4;
            /* border: 2px solid #5da5db; */
            border: none;
            border-radius: 4px;
            color: black;
            font-family: Helvetica;
            font-weight: bold;
            font-size: 14px;
            text-transform: uppercase;
            transition: 0.2s ease;
            cursor: pointer;
            }
            .login-button:hover{
              background-color:lightgreen;
            }

            .login-button2
            {
            vertical-align: middle;
            width: 20%;
            height: 45px; 
            background:#9AD8F4;
            /* border: 2px solid #5da5db; */
            border: none;
            border-radius: 4px;
            color: black;
            font-family: Helvetica;
            font-weight: bold;
            font-size: 14px;
            text-transform: uppercase;
            transition: 0.2s ease;
            cursor: pointer;
            }
            .login-button2:hover{
              background-color:red;
            }


          

  .teachertip {
    background-color: #9AD8F4;
    color:black;
    height:100%;
    width:5%;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    outline: none;
    font-size: 0;

    transition: width .5s, font-size .5s;
  }

  .teachertip:hover{
    width:20%;
    font-size: 20px;
  }
  .vert{

    padding:0;
    transform-origin: 40 170;

    transform: rotate(-90deg);
  }

  .tipButton {
    margin:3px;
    margin-right:3px;
    font-family: helvetica;
    border-radius: 7px;
    border: 1px solid gray;
    outline: none;

  }

  .tipButton:hover {
    background-color: lightgray;

  }

  .claim {
    margin:3px;
    margin-right:3px;
    font-family: helvetica;
    border-radius: 7px;
    font-size:20px;
    font-weight: bolder;
    height:50px;
    width:25%;
    border: 1px solid gray;
    outline: none;
  }

  .claim:hover {
    background-color: lightgreen;

  }

  #dropdown-basic-button{
    height:7%;
    z-index: 5004;
    top:30%;
    left:1%;
  }
  .dropdown {
    top:30%;
    left:100%;
    z-index: 5500;
  }

  .back {
    height:50px;
    border-radius: 5px;
    border:none;
    width:100px;
    font-family:helvetica;
    outline:none;
    position:absolute;
    left:3.5%;
    font-weight:bolder;
    top:87.5%;
     z-index:6001;
     /* background-color: #fff; */
     transition: .5s;
     padding-top: 7px;
    background-image: url(back.png);
    background-size: 30%, 120%;
    background-repeat: no-repeat;
    background-position: 5%, 5%;

    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);


  }

  .back:hover {
    background-color: red;
  }

  .filter {
    height:7.5%;
    border-radius: 5px;
    border:none;
    width:10%;
    font-family:helvetica;
    outline:none;
    padding-left:5px;
    background-color: #fff;
    position:absolute;
    right:1%;
    top:40%;
    font-weight:bolder;
    transition: .5s;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

     z-index:6001;
  }

  .typefilter {
    height:7.5%;
    border-radius: 5px;
    border:none;
    width:30%;
    font-family:helvetica;
    outline:none;
    padding-left:5px;
    background-color: #fff;
    font-weight:bolder;
    transition: .5s;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

     z-index:6001;
  }

  .addProj {
    height:7.5%;
    background-color: #fff;
    border:none;
    border-radius: 5px;
    width:10%;
    top:52.5%;
    font-weight:bolder;
    transition: .5s;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    font-family:helvetica;
    outline:none;
  }

  .addProj:hover {
    background-color: lightgray;
    width: 11%;

  }
  .filter:hover {
    background-color: lightgray;
    width: 11%;

  }

  .projectList {
    /* flexDirection:'column'; 
    display:"flex";  */
    /* display: flex; */
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    left:0%;
    position: relative;
    top:0;
    bottom:0;
    z-index:6000;
     height:100%;
     overflow:scroll;
     
     /* width: 100%;
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2; */
  }

  div.card {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  }

  #projectsBG {
    background-image: url("VAC_homescreen.PNG");

    /* Add the blur effect */
    filter: blur(8px);
    -webkit-filter: blur(8px);
    display: block;
    /* Full height */
    height: 100%;
    position:absolute;
    /* Center and scale the image nicely */
    background-repeat: no-repeat;
    background-size: cover;  }

    .progress {
     
      /* transform: rotate(-90deg); */
      height: 100px;
      width:80px;
      background-color: #c9c9c9;
      position: relative;
      margin-top:5%;
      /* transform: rotate(90deg); */

    }
    .progress:before {
      content: attr(data-label);
      font-size: 0.8em;
      /* position: absolute; */
      text-align: center;
      top: 5px;
      left: 0;
      right: 0;
    }

    .progress .value {
      background-color: #7cff87;
      display: inline-block;
      height: 100%;
    }

    .details {
      position: relative;
    }
    
    .details span {
      position: absolute;
      top: 0;
      transform: translateY(70%);  
      display: none;
      background: white;
      z-index: 20;
      min-width: 150px;
      padding: 1rem;
      box-shadow: 0 1px 0 1px rgba(0,0,0,.04);

    }
    
    .details:hover span {
      display: inline;
    }

    td {
      font-size: 18px;
    }

      .progress-circle {
  background-color: #ddd;
  border-radius: 50%;
  display: inline-block;
  height: 1.5rem;
  position: relative;
  width: 1.5rem;
  border-width: 1px;
}
.progress-circle:before {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  content: attr(data-progress) '%';
  display: flex;
  font-size: 0.5rem;
  justify-content: center;
  position: absolute;
  left: 1rem;
  right: 1rem;
  top: 1rem;
  bottom: 1rem;
  transition: transform 0.2s ease;
}
.progress-circle:after {
  background-color: #0083ff;
  border-radius: 50%;
  content: '';
  display: inline-block;
  height: 100%;
  width: 100%;
}
.progress-circle:hover:before,
.progress-circle:focus:before {
  transform: scale(0.8);
}
/**
* $step is set to 5 by default, meaning you can only use percentage classes in increments of five (e.g. 25, 30, 45, 50, and so on). This helps to reduce the size of the final CSS file. If you need a number that doesn't end in 0 or 5, you can change the text percentage while rounding the class up/down to the nearest 5.
*/
.progress-circle[data-progress="0"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(90deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="1"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(93.6deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="2"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(97.2deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="3"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(100.8deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="4"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(104.4deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="5"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(108deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="6"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(111.6deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="7"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(115.2deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="8"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(118.8deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="9"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(122.4deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="10"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(126deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="11"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(129.6deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="12"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(133.2deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="13"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(136.8deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="14"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(140.4deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="15"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(144deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="16"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(147.6deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="17"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(151.2deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="18"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(154.8deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="19"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(158.4deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="20"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(162deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="21"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(165.60000000000002deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="22"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(169.2deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="23"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(172.8deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="24"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(176.4deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="25"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(180deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="26"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(183.60000000000002deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="27"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(187.2deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="28"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(190.8deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="29"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(194.4deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="30"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(198deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="31"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(201.60000000000002deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="32"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(205.2deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="33"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(208.8deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="34"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(212.4deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="35"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(216deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="36"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(219.6deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="37"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(223.20000000000002deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="38"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(226.8deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="39"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(230.4deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="40"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(234deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="41"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(237.6deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="42"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(241.20000000000002deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="43"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(244.8deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="44"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(248.4deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="45"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(252deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="46"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(255.6deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="47"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(259.20000000000005deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="48"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(262.8deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="49"]:after {
  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(266.4deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="50"]:after {
  background-image: linear-gradient(-90deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="51"]:after {
  background-image: linear-gradient(-86.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="52"]:after {
  background-image: linear-gradient(-82.8deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="53"]:after {
  background-image: linear-gradient(-79.2deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="54"]:after {
  background-image: linear-gradient(-75.6deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="55"]:after {
  background-image: linear-gradient(-72deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="56"]:after {
  background-image: linear-gradient(-68.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="57"]:after {
  background-image: linear-gradient(-64.8deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="58"]:after {
  background-image: linear-gradient(-61.2deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="59"]:after {
  background-image: linear-gradient(-57.6deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="60"]:after {
  background-image: linear-gradient(-54deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="61"]:after {
  background-image: linear-gradient(-50.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="62"]:after {
  background-image: linear-gradient(-46.8deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="63"]:after {
  background-image: linear-gradient(-43.199999999999996deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="64"]:after {
  background-image: linear-gradient(-39.6deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="65"]:after {
  background-image: linear-gradient(-36deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="66"]:after {
  background-image: linear-gradient(-32.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="67"]:after {
  background-image: linear-gradient(-28.799999999999997deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="68"]:after {
  background-image: linear-gradient(-25.200000000000003deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="69"]:after {
  background-image: linear-gradient(-21.599999999999994deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="70"]:after {
  background-image: linear-gradient(-18deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="71"]:after {
  background-image: linear-gradient(-14.399999999999991deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="72"]:after {
  background-image: linear-gradient(-10.799999999999997deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="73"]:after {
  background-image: linear-gradient(-7.200000000000003deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="74"]:after {
  background-image: linear-gradient(-3.599999999999994deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="75"]:after {
  background-image: linear-gradient(0deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="76"]:after {
  background-image: linear-gradient(3.600000000000009deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="77"]:after {
  background-image: linear-gradient(7.200000000000003deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="78"]:after {
  background-image: linear-gradient(10.799999999999997deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="79"]:after {
  background-image: linear-gradient(14.400000000000006deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="80"]:after {
  background-image: linear-gradient(18deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="81"]:after {
  background-image: linear-gradient(21.60000000000001deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="82"]:after {
  background-image: linear-gradient(25.200000000000003deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="83"]:after {
  background-image: linear-gradient(28.799999999999997deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="84"]:after {
  background-image: linear-gradient(32.400000000000006deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="85"]:after {
  background-image: linear-gradient(36deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="86"]:after {
  background-image: linear-gradient(39.599999999999994deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="87"]:after {
  background-image: linear-gradient(43.20000000000002deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="88"]:after {
  background-image: linear-gradient(46.80000000000001deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="89"]:after {
  background-image: linear-gradient(50.400000000000006deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="90"]:after {
  background-image: linear-gradient(54deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="91"]:after {
  background-image: linear-gradient(57.599999999999994deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="92"]:after {
  background-image: linear-gradient(61.20000000000002deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="93"]:after {
  background-image: linear-gradient(64.80000000000001deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="94"]:after {
  background-image: linear-gradient(68.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="95"]:after {
  background-image: linear-gradient(72deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="96"]:after {
  background-image: linear-gradient(75.6deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="97"]:after {
  background-image: linear-gradient(79.20000000000002deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="98"]:after {
  background-image: linear-gradient(82.80000000000001deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="99"]:after {
  background-image: linear-gradient(86.4deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}
.progress-circle[data-progress="100"]:after {
  background-image: linear-gradient(90deg, #0083ff 50%, transparent 50%, transparent), linear-gradient(270deg, #0083ff 50%, #ddd 50%, #ddd);
}

.popup-container {
  width: 100%;
  height: 900px;
}
.popup-box {
    position: absolute;
/*    top: 10%;
    left: 50%;*/
    margin: 0 33%;
/*    margin-left: -250px;*/
    width: 420px;
  height: 200px;
    background-color: rgb(221, 221, 221);
    padding: 20px 40px;
    z-index: 1000;
  margin: auto;
  top: 0; left: 0; bottom: 0; right: 0;
  display: none;
}
.popup-box div {
    clear: both;
}

.popup-box h3 {
    display: inline;
}

.popup-box nav.close {
  float: right;
  font-size: 20px;
  color: #000000;
  background:green;
  z-index: 9999;
/*  opacity: 0.2;
  filter: alpha(opacity=20);*/
}


.wrapper{
position: relative;
margin-left: 10%;
margin-top: 15%;
}

.tooltip:hover span {
opacity: 1;
filter: alpha(opacity=100);
top: -6em;
left: 20em;
z-index: 99;
-webkit-transition: all 0.2s ease;
-moz-transition: all 0.2s ease;
-o-transition: all 0.2s ease;
transition: all 0.2s ease;
}

.box b {
  color: #fff;
}

.tooltip span {
  background: none repeat scroll 0 0 #222; /*-- some basic styling */
  color: #F0B015;
  font-family: 'Helvetica';
  font-size: 0.8em;
  font-weight: normal;
  line-height: 1.5em;
  padding: 16px 15px;
  width: 240px;
  top: -4em;  /*-- this is the original position of the tooltip when it's hidden */
  left: 20em;
  margin-left: 0; 
  /*-- set opacity to 0 otherwise our animations won't work */
  opacity: 0;
  filter: alpha(opacity=0);   
  position: absolute;
  text-align: center; 
  z-index: 2;
  text-transform: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease-in-out;
}

.tooltip span:after {
  border-color: #222 rgba(0, 0, 0, 0);
  border-style: solid;
  border-width: 15px 15px 0;
  bottom: -15px;
  content: "";
  display: block;
  left: 31px;
  position: absolute;
  width: 0;
}
.ui-tooltip {
    white-space: pre;
}

.tooltip-inner { white-space: pre-line; } 


/* Reset Select */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: black;
  background-image: none;
}
/* Remove IE arrow */

/* Custom Select */

select {

  padding: 0 .5em;
  cursor: pointer;
}



.btn {
  float: left;
  position: relative;
  top: 0;
  cursor: pointer;
  background: white;
  border-radius: 3px;
  box-shadow: 0px 4px rgba(0, 0, 0, 0.2);
  margin: 0 7px 11px 0;
  color: #888;

  text-align: center;

  /* so nobody selects the inside text on button */
  user-select: none;

  /* Smoothing out hover using css3 transitions, if not, the pressed button effect looks a bit hard */
  transition: all 0.2s ease;
}

.btn-small {
  line-height: 36px;
  width: 78px;
  height: 36px;
}

.btn-medium {
  line-height: 45px;
  width: 134px;
  height: 45px;
  font-size: 120%;
}

.btn-large {
  line-height: 80px;
  width: 100%;
  height: 85px;
  font-size: 160%;
}

/*==========  Different button styles  ==========*/

.submit {
  background: #b7de01;
  box-shadow: 0px 5px #687e00;
  color: #687e00;
}
.submit:hover {
  background: #99ba01;
  box-shadow: 0px 4px #435100;
  color: #687e00;
}
/* Imitate a pressed effect for buttons */
.submit:active {
  box-shadow: 0px 0px #717a33;
  top: 4px;
}


.cancel {
  background: #00ddeb;
  box-shadow: 0px 5px #028d96;
  color: white;
}

.cancel:hover {
  background: #01d1de;
  box-shadow: 0px 4px #006066;
  color: white;
}

/* Imitate a pressed effect for buttons */
.cancel:active {
  top: 4px;
  box-shadow: 0px 0px #d3545d;
}

.info {
  background: #d9008f;
  box-shadow: 0px 5px #5f023f;
  color: white;
}

.info:hover {
  background: #b60178;
  box-shadow: 0px 4px #3f002a;
  color: white;
}

/* Imitate a pressed effect for buttons */
.info:active {
  top: 4px;
  box-shadow: 0px 0px #d3545d;
}

.progress {
  padding:0;
  width:90%;
  height:10px;
  overflow:hidden;
  background:#1F2A37;
  border-radius:6px;
}

.bar {
  position:relative;
  float:left;
  min-width:1%;
  height:100%;
  background:#1F2A37;
}

.percent {
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  margin:0;
  font-family:tahoma,arial,helvetica;
  font-size:8px;
  color:white;
}

.progress-bar {
  height: 3px;
  width: 100%;
  position: relative;
  z-index: 10;
  background-color: #55708d;
}
.progress-bar .progress {
  position: relative;
  height: 100%;
  background-color: #cfd6dd;
  transition: width 0.5s ease-out;
  border-radius: 0px 2px 2px 0px;
}
.progress-bar .glow {
  width: 60px;
  max-width: 100%;
  height: 100%;
  float: right;
}
.progress-bar .glow::before,
.progress-bar .glow::after {
  content: "";
  display: block;
  position: relative;
  border-radius: 0px 2px 2px 0px;
}
.progress-bar .glow::before {
  background: transparent;
  height: 100%;
  box-shadow: 0px 0px 10px #cfd6dd, 0px 0px 10px #04bfff;
  z-index: -5;
}
.progress-bar .glow::after {
  background: linear-gradient(to right, #3d5980 0%, transparent 100%);
  height: calc(100% + 10px + 10px);
  width: calc(100% + 10px);
  top: -10px;
  left: -10px;
  z-index: -3;
}

.label {
  font-family: Poppins;
  color: white;
  text-shadow: 0px 0px 2px #cfd6dd;
  font-size: 24px;
}

.fill-1 {
  animation: fill-1 0.5s ease-out 0s;
  animation-fill-mode: forwards;
  width: 0%;
}

.fill-2 {
  animation: fill-2 0.5s ease-out 0.5s;
  animation-fill-mode: forwards;
  width: 0%;
}

.fill-3 {
  animation: fill-3 0.5s ease-out 1s;
  animation-fill-mode: forwards;
  width: 0%;
}

@keyframes fill-1 {
  0% {
    width: 0%;
  }
  100% {
    width: 70%;
  }
}
@keyframes fill-2 {
  0% {
    width: 0%;
  }
  100% {
    width: 40%;
  }
}
@keyframes fill-3 {
  0% {
    width: 0%;
  }
  100% {
    width: 90%;
  }
}
.label.one {
  animation: label 0.5s ease-out 0s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.label.two {
  animation: label 0.5s ease-out 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.label.three {
  animation: label 0.5s ease-out 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes label {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 0.9;
    transform: translateY(0);
  }
}
html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: #3d5980;
  box-sizing: border-box;
}

body {
  padding: 60px 20px;
}
body .wrapper {
  max-width: 600px;
  margin: 0 auto;
}
body .wrapper .container {
  padding: 20px;
}
body .wrapper .container > * {
  margin-bottom: 10px;
}
.glowing{
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
}

.glowingg{
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
}


.glowingtext {
  display: inline-block;
  color: white;
  font-family: 'Righteous', serif;
  text-shadow: .03em .03em 0 hsla(230,40%,50%,1);
  }
.glowingtext:after {
    content: attr(data-shadow);
    position: absolute;
    top: .06em; left: .06em;
    z-index: -1;
    text-shadow: none;
    background-image:
      linear-gradient(
        45deg,
        transparent 45%,
        hsla(48,20%,90%,1) 45%,
        hsla(48,20%,90%,1) 55%,
        transparent 0
        );
    background-size: .05em .05em;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  
    animation: shad-anim 15s linear infinite;
    }

@keyframes shad-anim {
  0% {background-position: 0 0}
  0% {background-position: 100% -100%}
  }


.shimmer {
  font-family: "Lato";
  margin: 0 auto;
  display: inline;
  margin-bottom: 0;
  font-family: "Lato";
  color: rgba(255,255,255,0.65);
  text-align: justify;
  line-height: 1.5em;
  margin: 10px 0 0 5px;
}
p a {
  text-decoration: none;
  color: #D4AF37;
}
p a:visted {
  color: #D4AF37;
}
.shimmer {
  text-align: center;
  color: rgba(255,255,255,0.1);
  background: -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
  background: -moz-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
  background: gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
  -webkit-background-size: 125px 100%;
  -moz-background-size: 125px 100%;
  background-size: 125px 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-animation-name: shimmer;
  -moz-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-duration: 5s;
  -moz-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #222;
}
@-moz-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-webkit-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@-o-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
@keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

.goldtext{
   font-family: 'Times New Roman',serif;
   letter-spacing: 2px;
   font-weight: bold;
   background-image: linear-gradient(
  to right,
  #462523 0,
        #cb9b51 22%, 
  #f6e27a 45%,
  #f6f2c0 50%,
  #f6e27a 55%,
  #cb9b51 78%,
  #462523 100%
  );
   color:transparent;
   -webkit-background-clip:text;

}

.video-game-button {
  text-shadow: 1px 1px pink, -1px -1px maroon;

  line-height: 1.5em;
  text-align: center;
  display: inline-block;
  -webkit-border-radius: .75em;
  -moz-border-radius: .75em;
  -o-border-radius: .75em;
    border-radius: .75em;
  background-color: red;
  -webkit-box-shadow:  0 .2em maroon;
  -moz-box-shadow:  0 .2em maroon;
  -o-box-shadow:  0 .2em maroon;
  box-shadow:  0 .2em maroon;
  color: red;
  margin: 5px;
  background-color: red;
  background-image: -o-linear-gradient(left top, pink 3%, red 22%, maroon 99%);
  background-image: -moz-linear-gradient(left top, pink 3%, red 22%, maroon 99%);
  background-image: -webkit-linear-gradient(left top, pink 3%, red 22%, maroon 99%);
  background-image: linear-gradient(left top, pink 3%, red 22%, maroon 99%);
  cursor: pointer;
  padding-left: 5px;
}      

.homeDropdownOptions
{
  color: white;
  overflow: hidden;
  cursor: pointer;
}

.homeNavButton1
{
  padding: 2px;
  background: none;
  color: white;
  z-index: 5000;
  border-width: 2px;
  border-style: solid;
  border-color: white;
   font-size: 0.6em;
  font-weight: bold;
  overflow: hidden;
  cursor: pointer;
  transition: 0.8s;

}

.homeNavButton2
{
  padding: 2px;
  background: none;
  color: white;
  z-index: 5000;
  border-width: 2px;
  border-style: solid;
  border-color: white;
    font-size: 0.6em;
  font-weight: bold;
  cursor: pointer;
  transition: 0.8s;

}

.homeNavButton3
{
  padding: 2px;
  background: none;
  color: white;
  z-index: 5000;
  border-width: 2px;
  border-style: solid;
  border-color: white;
   font-size: 0.6em;
  font-weight: bold;
  overflow: hidden;
  cursor: pointer;
  transition: 0.8s;

}

.homeNavButton4
{
  padding: 2px;
  background: none;
  color: white;
  z-index: 5000;
  border-width: 2px;
  border-style: solid;
  border-color: white;
  font-size: 0.6em;
  font-weight: bold;
  overflow: hidden;
  cursor: pointer;
  transition: 0.8s;
  position: relative;
}

.homeNavButton1:hover, .homeNavButton2:hover, .homeNavButton3:hover, .homeNavButton4:hover
{
  color: #29364F;
}

.homeNavButton1::before, .homeNavButton2::before, .homeNavButton3::before, .homeNavButton4::before
{
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 0%;
  background: white;
  z-index: -1;
  transition: 0.8s;
  top: 0;
  border-radius: 0 0 50% 50%;
}

.homeNavButton1:hover::before, .homeNavButton2:hover::before, .homeNavButton3:hover::before, .homeNavButton4:hover::before
{
  height: 180%;
}

.homeNavSelector
{
  background-color: #1F2A37;
  width: 150px;
  color: white;
  border: 0;
}

.backgroundGif
{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}




.button-1
{
  color: white;
  font-weight: bold;
}

.button-1:hover
{
  color:black;
  transition: 0.6s
}


.button-1::before, .button-2::before
{
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 0%;
  background: white;
  z-index: -1;
  transition: 0.6s;
  top: 0;
  border-radius: 0 0 50% 50%;
  color:black;
}

.button-1:hover::before, .button-2:hover::before
{
  height: 180%;
  color:black;
}


.homeBeginnerButtons {
  margin: 5px 0 5px 0;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  transition: all 0.25s ease;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  border-radius: 50px;

  border-radius: 50px;
  background-color: #e74c3c;
  color: white;
  box-shadow: 0px 4px rgba(0, 0, 0, 0.2);
  margin: 0 7px 11px 0;
  white-space: pre-wrap;
  transition: 0.6s;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.homeNavButton1
{
  padding: 2px;
  width: 55px;
  background: none;
  color: white;
  z-index: 5000;
  border-width: 2px;
  border-style: solid;
  border-color: white;
  font-size: 10px;
  font-weight: bold;
  overflow: hidden;
  cursor: pointer;
  transition: 0.8s;
  position: relative;
}


.homeNavButton1:hover, .homeNavButton2:hover, .homeNavButton3:hover, .homeNavButton4:hover
{
  color: #29364F;
}

.homeNavButton1::before, .homeNavButton2::before, .homeNavButton3::before, .homeNavButton4::before
{
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 0%;
  background: white;
  z-index: -1;
  transition: 0.8s;
  top: 0;
  border-radius: 0 0 50% 50%;
}

.homeNavButton1:hover::before, .homeNavButton2:hover::before, .homeNavButton3:hover::before, .homeNavButton4:hover::before
{
  height: 180%;
}


.homeNavButton2
{
  padding: 2px;
  width: 40px;
  background: none;
  color: white;
  z-index: 5000;
  border-width: 2px;
  border-style: solid;
  border-color: white;
  font-size: 10px;
  font-weight: bold;
  overflow: hidden;
  cursor: pointer;
  transition: 0.8s;
  position: relative;
}

.homeNavButton3
{
  padding: 2px;
  width: 75px;
  background: none;
  color: white;
  z-index: 5000;
  border-width: 2px;
  border-style: solid;
  border-color: white;
  font-size: 10px;
  font-weight: bold;
  overflow: hidden;
  cursor: pointer;
  transition: 0.8s;
  position: relative;
}

.homeNavButton4
{
  padding: 2px;
  width: 55px;
  background: none;
  color: white;
  z-index: 5000;
  border-width: 2px;
  border-style: solid;
  border-color: white;
  font-size: 10px;
  font-weight: bold;
  overflow: hidden;
  cursor: pointer;
  transition: 0.8s;
  position: relative;
}

.loginBox
{
  width: 600px;
  padding: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #191919;
  text-align: center;
  font-family: sans-serif;
}

.loginBox h1
{
  color: white;
  font-weight: 500;
  font-size: 30px;
  margin: 15px;
}

.loginBox input[type = "text"], .loginBox input[type = "password"]
{
  border: 0;
  background: none;
  display: block;
  margin: 20px auto;
  text-align: center;
  border: 2px solid #3498db;
  padding: 14px 10px;
  width: 200px;
  outline: none;
  color: white;
  border-radius: 24px;
  transition: 0.25s;
}

.loginBox input[type = "text"]:focus, .loginBox input[type = "password"]:focus
{
  border-color: #2ecc71;
}

.loginBox button
{
  border: 0;
  background: none;
  display: block;
  margin: 20px auto;
  text-align: center;
  border: 2px solid #2ecc71;
  padding: 10px 30px;
  outline: none;
  color: white;
  border-radius: 24px;
  transition: 0.25s;
  cursor: pointer;
}

.loginBox button:hover
{
  background: #2ecc71;
}

.loginBottom
{
  margin: 3px 7px;
  font-size: 12px;
  color: white;
  cursor: pointer;
  font-family: sans-serif;
  transition: 0.25s;
  padding: 3px 7px;
}

.loginBottom:hover
{
  color: #2ecc71;
} 

.scrollable-content {
    height: 100px;
    width: 450px;
    overflow: scroll;
    overflow-x: hidden;
}

.scrollable-content label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    text-overflow: clip;
    text-overflow: ellipsis;
    white-space: nowrap;
}


/* custom styles */

label {
  display: block;
}
input {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #fff;
  padding: 3px 5px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
}

.glowing-div {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-family: Arial;
  font-size: 10px;

  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  -webkit-animation: glowingnavy 1500ms infinite;
  -moz-animation: glowingnavy 1500ms infinite;
  -o-animation: glowingnavy 1500ms infinite;
  animation: glowingnavy 1500ms infinite;
  width: 120%;
}

.glowing-button {
  background-color: #004a7f;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Arial;
  font-size: 10px;

  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;

}

@-webkit-keyframes glowing {
  0% {
    background-color: #b20000;
    -webkit-box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    -webkit-box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    -webkit-box-shadow: 0 0 3px #b20000;
  }
}

@-moz-keyframes glowing {
  0% {
    background-color: #b20000;
    -moz-box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    -moz-box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    -moz-box-shadow: 0 0 3px #b20000;
  }
}

@-o-keyframes glowing {
  0% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
  
}

@keyframes glowing {
  0% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
}

@keyframes glowingnavy {
  0% {
    background-color: navy;
    box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: navy;
    box-shadow: 0 0 40px #ff0000;
  }
  100% {
    background-color: navy;
    box-shadow: 0 0 3px #b20000;
  }
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

div::-webkit-scrollbar {
  width: 11px;
}

div::-webkit-scrollbar-track {
  background: #154734;
}

div::-webkit-scrollbar-thumb {
  background-color: #f3f0dd;
  border-radius: 6px;
  border: 3px solid #154734;
}

.glow_div {
  /*margin-top: 100px;*/
  text-align: center;
  display: block;
  margin: 120px 0;
}
.glow_div span {
  border-radius: 25px;
  color: #fff;
  box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 20px #0ba9ca, 0 0 30px #0ba9ca,
    0 0 40px #0ba9ca, 0 0 50px #0ba9ca;
  -webkit-animation: blink 0.7s infinite alternate;
  animation: blink 0.7s infinite alternate;
}



.progress {
  background: rgba(255,255,255,0.1);
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  padding: 0 5px;
  display: flex;
  height: 40px;
}

.progress-value {
  animation: load 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: red;
  height: 30px;
  width: 0;
}

@keyframes load {
  0% { width: 0; }
  100% { width: 68%; }
}




@-webkit-keyframes animate-width {
  0% {
    width: 0;
  }
  100% {
    visibility: visible;
  }
}
@-moz-keyframes animate-width {
  0% {
    width: 0;
  }
  100% {
    visibility: visible;
  }
}
@keyframes animate-width {
  0% {
    width: 0;
  }
  100% {
    visibility: visible;
  }
}
@-webkit-keyframes animate-height {
  0% {
    height: 0;
  }
  100% {
    visibility: visible;
  }
}
@-moz-keyframes animate-height {
  0% {
    height: 0;
  }
  100% {
    visibility: visible;
  }
}
@keyframes animate-height {
  0% {
    height: 0;
  }
  100% {
    visibility: visible;
  }
}


.stat-levels {
  box-shadow: inset 0 0 25px rgba(0, 0, 0, 0.5);
  max-width: 45%;
  margin: 50px auto 0 auto;
  padding: 25px;
  border: 1px solid #494a4e;
  border-radius: 10px;
  background: -webkit-linear-gradient(bottom, #444549 0%, #343539 100%);
  background: linear-gradient(#444549 0%, #343539 100%);
}

.stat-bar {
  background-color: #2a2b2f;
  box-shadow: inset 0 5px 15px rgba(0, 0, 0, 0.6);
  height: 12px;
  overflow: hidden;
  padding: 3px;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-left: 0;
}
.stat-bar:last-child {
  margin-bottom: 0;
}

.stat-bar-rating {
  border-radius: 4px;
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  color: white;
  text-align: center;
  text-indent: -9999px;
  background-color: #428bca;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
}

.stat-1 > .stat-bar-rating {
  background-image: -webkit-linear-gradient(bottom, #ff4500 0%, #ff4500 47%, #cf3a02 50%, #cf3a02 100%);
  background-image: linear-gradient(to bottom, #ff4500 0%, #ff4500 47%, #cf3a02 50%, #cf3a02 100%);
}

.stat-2 > .stat-bar-rating {
  background-image: -webkit-linear-gradient(bottom, #b8f123 0%, #b8f123 47%, #79a602 50%, #79a602 100%);
  background-image: linear-gradient(to bottom, #b8f123 0%, #b8f123 47%, #79a602 50%, #79a602 100%);
}

.stat-3 > .stat-bar-rating {
  background-image: -webkit-linear-gradient(bottom, #00c5ff 0%, #00c5ff 47%, #0383a9 50%, #0383a9 100%);
  background-image: linear-gradient(to bottom, #00c5ff 0%, #00c5ff 47%, #0383a9 50%, #0383a9 100%);
}

.stat-bar-rating {
  visibility: hidden;
  width: 0;
  -webkit-animation: animate-width;
  -moz-animation: animate-width;
  animation: animate-width;
  animation-timing-function: cubic-bezier(0.35, 0.95, 0.67, 0.99);
  -webkit-animation-timing-function: cubic-bezier(0.35, 0.95, 0.67, 0.99);
  -moz-animation-timing-function: cubic-bezier(0.35, 0.95, 0.67, 0.99);
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.stat-bar:nth-of-type(1) .stat-bar-rating {
  animation-delay: 0.25s;
  -webkit-animation-delay: 0.25s;
}
.stat-bar:nth-of-type(2) .stat-bar-rating {
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
}
.stat-bar:nth-of-type(3) .stat-bar-rating {
  animation-delay: 0.75s;
  -webkit-animation-delay: 0.75s;
}


.drawer.drawer-closed {
  width: 370px;
  height: 100vh;
  background-color: #fff;
  margin-left: -370px;
  float: left;
  box-shadow: 0px 0px 5px #7f7f7f;
}

.drawer {
  width: 370px;
  height: 100vh;
  background-color: #fff;
  margin-left: 0px;
  float: left;
  box-shadow: 0px 0px 5px #7f7f7f;
}


#drawerOpener {
  float: left;
  margin-top: 10px;
  margin-left: 18px;
}

@keyframes rotation {
  from {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(5deg);
  }
}



.rotate {
  animation: rotation 4s infinite linear; animation-direction: alternate;
}

.leaderboardmain
      {
        background-color: #587B69;
      }

      .mainTable
      {
        position: absolute;
        left: 0%;
        top: 5%;
        width:100%;
        z-index: 1000;
      }

      .leaderTable
      {
        border-collapse: collapse;
        font-size: 0.9em;
        position: relative;
        table-layout: fixed;
        width: 100%;
        min-width: 400px;
        border-radius: 5px 5px 0 0;
        overflow: hidden;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
      }

      .leaderTable thead tr
      {
        background-color: #009879;
        color: white;
        text-align: left;
        font-weight: bold;
      }

      .leaderTable th, .leaderTable td
      {
        padding: 12px 15px;
        position: sticky;
        top: 0;
      }

      .leaderTable tbody tr
      {
        border-bottom: 1px solid #dddddd;
      }

      .leaderTable tbody tr:nth-of-type(even)
      {
        background-color: #f3f3f3;
      }

      .leaderTable tbody tr:nth-of-type(odd)
      {
        background-color: white;
      }

      .leaderTable tbody tr:last-of-type
      {
        border-bottom: 2px solid #009879;
      }

      .welcomeText
      {
        text-align: center;
        transform: translate(-50%, -50%);
        width: 100%;
      }

      .welcomeText span
      {
        text-transform: uppercase;
      }

      .text1
      {
        color: white;
        font-size: 6vw;
        font-weight: 700;
        font: candara;
        letter-spacing: 8px;
        margin-bottom: 20px;
        background: #587B69;
        animation: text 3s 1;
      }

      .text2
      {
        font-size: 3vw;
        font: garamond;
        color: #6ab04c;
      }

      @keyframes text
      {
        0%
        {
          color: #1f1f1f;
          margin-bottom: -40px;
        }
        30%
        {
          letter-spacing: 25px;
          margin-bottom: -40px;
        }
        85%
        {
          letter-spacing: 8px;
          margin-bottom: -40px;
        }
      }

      .trophyImage
      {
        width: 60px;
        height: 60px;
        margin: -12px;
      }

      .backgroundLeft
      {
        position: absolute;
        left: 0%;
        top: 0%;
        height: 100%;
        width: 20%;
        box-shadow: 20px 0 20px rgba(0, 0, 0, 0.35);
      }

      .backgroundRight
      {
        position: absolute;
        right: 0%;
        top: 0%;
        height: 100%;
        width: 20%;
        box-shadow: -20px 0 20px rgba(0, 0, 0, 0.35);
      }


      
.accordion label {
  display:block;
  background-color: #eeeeee;
  color: #424242;
  cursor: pointer;
  border-bottom: 1px solid #bdbdbd;
  border-top: 1px solid #ffffff;
}

.accordion label h2 {
  font-size:1.3vw;
}
.accordion p {
  color: #424242;
  padding: 10px;
  font-size: 0.8em;
  line-height: 1.7em;
  opacity: 0;
  display: none;
  text-align: left;
  background-color: #fff;
  margin: 0px;
}
#tm:checked ~ .hiddentext {
  display: block;
  opacity: 1;
}
input#tm {
  display: none;
  position: relative;
}


#tn3:checked ~ .hiddentext {
  display: block;
  opacity: 1;
}
input#tn3 {
  display: none;
  position: relative;
}

#tn4:checked ~ .hiddentext {
  display: block;
  opacity: 1;
}
input#tn4 {
  display: none;
  position: relative;
}

#tn5:checked ~ .hiddentext {
  display: block;
  opacity: 1;
}
input#tn5 {
  display: none;
  position: relative;
}

#tn6:checked ~ .hiddentext {
  display: block;
  opacity: 1;
}
input#tn6 {
  display: none;
  position: relative;
}

#tn7:checked ~ .hiddentext {
  display: block;
  opacity: 1;
}
input#tn7 {
  display: none;
  position: relative;
}

#tn8:checked ~ .hiddentext {
  display: block;
  opacity: 1;
}
input#tn8 {
  display: none;
  position: relative;
}

#tn9:checked ~ .hiddentext {
  display: block;
  opacity: 1;
}
input#tn9 {
  display: none;
  position: relative;
}

#tn10:checked ~ .hiddentext {
  display: block;
  opacity: 1;
}
input#tn10 {
  display: none;
  position: relative;
}

#tn12:checked ~ .hiddentext {
  display: block;
  opacity: 1;
}
input#tn12 {
  display: none;
  position: relative;
}

#tn11:checked ~ .hiddentext {
  display: block;
  opacity: 1;
}
input#tn11 {
  display: none;
  position: relative;
}

#tn:checked ~ .hiddentext {
  display: block;
  opacity: 1;
}
input#tn {
  display: none;
  position: relative;
}
#to:checked ~ .hiddentext {
  display: block;
  opacity: 1;
}
input#to {
  display: none;
  position: relative;
}

.tn:checked ~ .hiddentext {
  display: block;
  opacity: 1;
}
input.tn {
  display: none;
  position: relative;
}
.arrow{
  color: #666666;
}

/* BUTTON 2 */

.teacherbtn,
.teacherbtn:link,
.teacherbtn2:visited {
  background-color: #5b657c;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -3px 0 rgba(0, 0, 0, 0.15) inset;
  color: #fff;
  letter-spacing: 0.1em;
  cursor: pointer;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
  transition-property: transform;
  transform: translateZ(0);
  transition: box-shadow 0.5s cubic-bezier(0.39, 0.5, 0.15, 1.36);

  &:hover {
    box-shadow: 0 0 0 28px rgba(0, 0, 0, 0.25) inset;
  }

  &:active {
    transform: translateY(3px);
  }
}
